import { graphql, useStaticQuery } from "gatsby"

export type ContentfulMediaProps = {
  title: string
  size: number
  url: string
  mimeType: string
  width: number
  height: number
}

export type ContentfulAboutEntriesProps = {
  title: string
  description: string
  imageLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
}

export type ContentfulFaqEntriesProps = {
  list: {
    question: {
      text: string
    }
    answer: {
      raw: string
    }
  }[]
}

export type ContentfulFooterListProps = {
  title: string
  link: string
}

export type ContentfulFooterEntriesProps = {
  titleLeft: string
  listLeft: ContentfulFooterListProps[]
  titleRight: string
  listRight: ContentfulFooterListProps[]
}

export type ContentfulHeroEntryFieldsProps = {
  title: string
  subtitle: {
    text: string
  }
  buttonPrimaryPreLabel?: string
  buttonPrimaryLabel?: string
  buttonPrimaryLink?: string
  buttonSecondaryPreLabel?: string
  buttonSecondaryLabel?: string
  buttonSecondaryLink?: string
  imageLandscape: ContentfulMediaProps
  videoLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  id: string
}

export type ContentfulHeroEntriesProps = {
  list: ContentfulHeroEntryFieldsProps[]
}

export type ContentfulMissionsEntriesProps = {
  list: {
    title: string
    description: {
      text: string
    }
    buttonPrimaryLabel: string
    buttonPrimaryLink: string
    buttonSecondaryLabel: string
    buttonSecondaryLink: string
    id: string
  }[]
}

export type ContentfulTrendingEntriesProps = {
  list: {
    title: string
    buttonPrimaryLabel: string
    buttonPrimaryLink: string
    image: ContentfulMediaProps
    background: ContentfulMediaProps
    id: string
  }[]
}

export type ContentfulWorldsEntriesProps = {
  title: string
  subtitle: string
  description: {
    text: string
  }
  buttonPrimaryLabel: string
  buttonPrimaryLink: string
  buttonSecondaryLabel: string
  buttonSecondaryLink: string
  imageLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
}

export type ContentfulCreatorsHeroEntryFieldsProps = {
  titleFirstLine: string
  changingWord: string[]
  titleLastLine: string
  subtitle: string
  imageLandscape: ContentfulMediaProps
  videoLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  id: string
}

export type ContentfulCreatorsWhyEntryProps = {
  title: string
  description: string
  image: ContentfulMediaProps
  id: string
}

export type ContentfulCreatorsWhyEntriesProps = {
  list: ContentfulCreatorsWhyEntryProps[]
}

export type ContentfulCreatorsCreateEntryProps = {
  title: string
  description: string
  image: ContentfulMediaProps
  imageBackground: ContentfulMediaProps
  tab1Title: string
  tab1DescriptionTitle: string
  tab1DescriptionSubTitle: string
  tab1Skills: string[]
  tab1Links: {
    raw: string
  }
  tab2Title: string
  tab2DescriptionTitle: string
  tab2DescriptionSubTitle: string
  tab2Skills: string[]
  tab2Links: {
    raw: string
  }
  id: string
}

export type ContentfulCreatorsCreateEntriesProps = {
  list: ContentfulCreatorsCreateEntryProps[]
}

export type ContentfulCreatorsConnectEntryProps = {
  name: string
  description: string
  image: ContentfulMediaProps
  url: string
  id: string
}

export type ContentfulCreatorsConnectEntriesProps = {
  list: ContentfulCreatorsConnectEntryProps[]
}

export type ContentfulCreatorsLearnEntryProps = {
  title: string
  name: string
  userImage: ContentfulMediaProps
  image: ContentfulMediaProps
  url: string
  date: string
  id: string
}

export type ContentfulCreatorsLearnEntriesProps = {
  list: ContentfulCreatorsLearnEntryProps[]
}

export type ContentfulLandingContentProps = {
  hero: ContentfulHeroEntriesProps
  about: ContentfulAboutEntriesProps
  missions: ContentfulMissionsEntriesProps
  trending: ContentfulTrendingEntriesProps
  worlds: ContentfulWorldsEntriesProps
  faq: ContentfulFaqEntriesProps
  footer: ContentfulFooterEntriesProps
  creatorsHero: ContentfulCreatorsHeroEntryFieldsProps
  creatorsWhy: ContentfulCreatorsWhyEntriesProps
  creatorsCreate: ContentfulCreatorsCreateEntriesProps
  creatorsConnect: ContentfulCreatorsConnectEntriesProps
  creatorsLearn: ContentfulCreatorsLearnEntriesProps
  creatorsFaq: ContentfulFaqEntriesProps
  supportFaq: ContentfulFaqEntriesProps
}

export default function useLandingContentfulQuery(): ContentfulLandingContentProps {
  return useStaticQuery(graphql`
    query LandingQuery {
      hero: contentfulLandingHeroSection(contentfulid: { eq: "hero" }) {
        list {
          title
          subtitle {
            text: subtitle
          }
          buttonPrimaryPreLabel
          buttonPrimaryLabel
          buttonPrimaryLink
          buttonSecondaryPreLabel
          buttonSecondaryLabel
          buttonSecondaryLink
          imageLandscape {
            title
            size
            url
            mimeType
            width
            height
          }
          videoLandscape {
            title
            size
            url
            mimeType
            width
            height
          }
          imagePortrait {
            title
            size
            url
            mimeType
            width
            height
          }
          videoPortrait {
            title
            size
            url
            mimeType
            width
            height
          }
          id: contentfulid
        }
      }
      about: contentfulLandingAbout(contentfulid: { eq: "about" }) {
        title
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
      }
      missions: contentfulLandingMissionsSection(
        contentfulid: { eq: "missions" }
      ) {
        list {
          title
          description {
            text: description
          }
          buttonPrimaryLabel
          buttonPrimaryLink
          buttonSecondaryLabel
          buttonSecondaryLink
          id: contentfulid
        }
      }
      trending: contentfulLandingTrendingSection(
        contentfulid: { eq: "trendings" }
      ) {
        list {
          title
          buttonPrimaryLabel
          buttonPrimaryLink
          image {
            title
            size
            url
            mimeType
            width
            height
          }
          background {
            title
            size
            url
            mimeType
            width
            height
          }
          id
        }
      }
      worlds: contentfulLandingWorlds(contentfulid: { eq: "worlds" }) {
        title
        subtitle
        description {
          text: description
        }
        buttonPrimaryLabel
        buttonPrimaryLink
        buttonSecondaryLabel
        buttonSecondaryLink
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
      }
      faq: contentfulLandingFaqSection(contentfulid: { eq: "faq" }) {
        list {
          question {
            text: question
          }
          answer {
            raw
          }
        }
      }
      footer: contentfulLandingFooterSection(contentfulid: { eq: "footer" }) {
        titleLeft
        listLeft {
          title
          link
        }
        titleRight
        listRight {
          title
          link
        }
      }
      creatorsHero: contentfulCreatorsLandingHero(
        contentfulid: { eq: "hero" }
      ) {
        titleFirstLine
        changingWord
        titleLastLine
        subtitle
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        videoLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        videoPortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        id: contentfulid
      }
      creatorsWhy: contentfulCreatorsLandingWhySection(
        contentfulid: { eq: "why" }
      ) {
        list {
          title
          description
          image {
            title
            size
            url
            mimeType
            width
            height
          }
          id: contentfulid
        }
      }
      creatorsCreate: contentfulCreatorsLandingCreateSection(
        contentfulid: { eq: "create" }
      ) {
        list {
          id: contentfulid
          title
          description
          image {
            title
            size
            url
            mimeType
            width
            height
          }
          imageBackground {
            title
            size
            url
            mimeType
            width
            height
          }
          tab1Title
          tab1DescriptionTitle
          tab1DescriptionSubTitle
          tab1Skills
          tab1Links {
            raw
          }
          tab2Title
          tab2DescriptionTitle
          tab2DescriptionSubTitle
          tab2Skills
          tab2Links {
            raw
          }
        }
      }
      creatorsConnect: contentfulCreatorsLandingConnectSection(
        contentfulid: { eq: "connect" }
      ) {
        list {
          name
          description
          image {
            title
            size
            url
            mimeType
            width
            height
          }
          url
          id: contentfulid
        }
      }
      creatorsLearn: contentfulCreatorsLandingLearnSection(
        contentfulid: { eq: "learn" }
      ) {
        list {
          title
          image {
            title
            size
            url
            mimeType
            width
            height
          }
          name
          userImage {
            title
            size
            url
            mimeType
            width
            height
          }
          url
          date(formatString: "MMMM DD, YYYY")
          id: contentfulid
        }
      }
      creatorsFaq: contentfulCreatorsLandingFaqSection(
        contentfulid: { eq: "faq" }
      ) {
        list {
          question {
            text: question
          }
          answer {
            raw
          }
        }
      }
      supportFaq: contentfulSupportFaqSection(
        contentfulid: { eq: "faq_support" }
      ) {
        list {
          question {
            text: question
          }
          answer {
            raw
          }
        }
      }
    }
  `)
}
